import $ from 'jquery';
import 'slick-carousel';

$(document).ready(function() {

	$('.actus_block-slider').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: true,
		dots: false,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		prevArrow: $('.actus_block-slider-arrow-prev'),
		nextArrow: $('.actus_block-slider-arrow-next')
	});
	
	$('.testimonials-slider_text').slick({
		autoplay: false,
		arrows: false,
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1
	});

	$('.testimonials-slider').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
		dots: true,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		rows: 1,
		prevArrow: $('.testimonials-slider-arrow-prev'),
		nextArrow: $('.testimonials-slider-arrow-next'),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					arrows: true,
					prevArrow: $('.testimonials-slider-arrow-prev'),
					nextArrow: $('.testimonials-slider-arrow-next'),
					dots: false,
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 600,
				settings: {
					arrows: true,
					prevArrow: $('.testimonials-slider-arrow-prev'),
					nextArrow: $('.testimonials-slider-arrow-next'),
					dots: false,
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					dots: false,
					arrows: true,
					prevArrow: $('.testimonials-slider-arrow-prev'),
					nextArrow: $('.testimonials-slider-arrow-next'),
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.slick_center').slick({
		dots: true,
		centerMode: true,
		centerPadding: '20px',
		slidesToShow: 3,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 3
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});


	/*
	$("div[class^=slider_column_]").each(function () {
		let id = $(this).attr('class').split("_").at(-1);
		$(".slider_column_" + id).slick({
			arrows: true,
			dots: false,
			infinite: true,
			speed: 300,
			slidesToShow: 4,
			slidesToScroll: 4,
			prevArrow: $('.slider_column_prev_' + id),
			nextArrow: $('.slider_column_next_' + id),
			responsive: [
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		});
	});
	*/


});