import $ from 'jquery';
import AOS from 'aos';

$(document).ready(function() {

    $(function() {
        AOS.init();
    });


    $('.count').each(function (index, el) {
        let launched = false;
        $(window).scroll(function() {
            if (launched) return;
            const top = $(window).scrollTop() + $(window).outerHeight(true);
            const countBottom = $(el).offset().top + $(el).outerHeight(true);
            if (top >= countBottom) {
                launched = true;
                $(el).prop('Counter', 0).animate({
                    Counter: $(el).text()
                }, {
                    duration: 6000,
                    easing: 'swing',
                    step: function (now) {
                        $(el).text(Math.ceil(now));
                    }
                });
            }
        });
    });

    //new Glide('.glide').mount();

});